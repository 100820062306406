/* HTML: <div class="loader2"></div> */
html, body, #root, .App {
    height: 100%;
}
.LoaderContainer {
    display: none;
}
.wrapperContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    aspect-ratio: 16/9;
    overflow: hidden;
    width: 100%;
}

.wrapperContainer > div {
    width: 100%;
    height: 100%;
}
.wrapperContainer .LoaderContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
}

video {
    aspect-ratio: 16/9;
}
/* .wrapperContainer .LoaderContainer .loader2 {
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #25b09b;
    --_m: 
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: loaderNormal 1s infinite linear;
  }
  @keyframes loaderNormal {to{transform: rotate(1turn)}} */


  /* HTML: <div class="loader"></div> */
  .wrapperContainer .LoaderContainer .loader2 {
    font-weight: bold;
    font-family: sans-serif;
    font-size: 30px;
    animation: l1 1s linear infinite alternate;
  }
  .wrapperContainer .LoaderContainer .loader2:before {
    content:"YourERPCoach"
  }
  @keyframes l1 {to{opacity: 0}}