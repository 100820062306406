.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.h-100 {
  min-height: 100%;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.w-100 {
  width: 100%;
}

.max-width-3 {
  max-width: 300px;
}

.flex-column {
  flex-direction: column;
}

.link {
  margin-top: 1rem;
  font-size: 1rem;
}

#error-page {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

.cardError {
  padding: 16px;
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  flex-direction: column;
}

.VideoError img{
  max-width: 100px;
}

.cardError > * {
  color: red;
  margin: 0;
  text-align: center;
}